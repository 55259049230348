<template>
    <div class="main_r_info_box">
        <ul v-if="articleList.length > 0" v-for="(sitem,j) in articleList" :key="j" class="main_r_info_box_ul">
            <h4 style="width:100%;text-align:left;margin-bottom:20px;padding-left: 14px;color: rgb(24, 119, 201);overflow:hidden;">
                <span style="display:inline-block;float: left;">{{sitem.dictLabel}}</span>
                <span style="display:inline-block;width:80%;border-bottom: 1px solid rgb(227, 227, 227);margin-left:9px;float: left;margin-top: 13px;"></span>
            </h4>
            <li v-for="(item,i) in sitem.children" :key="i">
                <a :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" :title="item.contentTitle" target="_blank" style="display:block;">
                    <div class="main_r_info_box_li_imgbox">
                        <img class="main_img" :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                    </div>
                    <h4 class="main_r_info_box_li_h4">{{item.contentTitle ? item.contentTitle : ''}}</h4>
                    <p class="main_r_info_box_li_p">{{item.contentDescription ? item.contentDescription : ''}}</p>
                </a>
            </li>
        </ul>
        <!-- <div v-if="articleList.length > 0" class="page_box">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageNum"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div> -->
        <el-empty v-if="articleList.length <= 0" description="暂无数据!"></el-empty>
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'imglist',
    props:['currentId'],
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            articleList: [],//右侧展示文章列表数据
            pageNum: 1,//分页的三个值
            pageSize: 100,
            total: 0,
            curtitle: ''
        }
    },
    watch: {
        currentId (val) {
            this.rightInfoFn(val);
        }
    },
    mounted() {
        this.rightInfoFn(this.currentId);//默认展示CIKD大讲堂
    },
    methods: {
        //加载右侧文章列表数据
        rightInfoFn(index, curNum=1){
            let _this = this;
            let menuId = index;
            let pageNum = curNum;
            let pageSize = 100;
            api.getArticleList(menuId, pageNum, pageSize).then(res => {
                if (res.data) {
                    _this.pageNum = res.data.pageNum;
                    _this.pageSize =res.data.pageSize;
                    _this.total =res.data.total;
                    _this.$emit('titleInfo',res.data.categoryPosition);//将当前所属栏目传给父组件
                    _this.curtitle = res.data.title;

                    let newData = res.data.teamNameList;
                    let _list = res.data.list;
                    for(let i=0;i<newData.length;i++){
                        newData[i].children = [];
                        for(let j=0;j<_list.length;j++){
                            if(newData[i].dictValue == _list[j].contentType){
                                newData[i].children.push(_list[j]);
                            }
                        }
                    }
                    _this.articleList = newData;
                    console.log(newData,'newData');
                }
            });
        },
        //分页操作函数1
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        //分页操作函数2
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.rightInfoFn(this.currentId, val);
        }
    }
}
</script>

<style scoped>

.main_r_info_box{
    width:100%;
    margin-top:38px;
}
.main_r_info_box_ul{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.main_r_info_box_ul li{
    width: 210px;
    height:228px;
    overflow: hidden;
    float: left;
    margin-right: 12px;
    margin-bottom: 44px;
}
.main_r_info_box_ul li:nth-child(4n){
    margin-right: 0;
}
/* .main_r_info_box_ul li:nth-child(6){
    margin-right: 0;
}
.main_r_info_box_ul li:nth-child(9){
    margin-right: 0;
} */
.main_r_info_box_li_imgbox{
    width: 140px;
    height: 168px;
    margin:0 auto;
    overflow: hidden;
    position: relative;
}
.main_r_info_box_li_imgbox .main_img{
    width:100%;
    display: block;
}
.main_r_info_box_li_p{
    width: 100%;
    height: 16px;
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    text-align: center;
    margin-top: 9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.main_r_info_box_li_h4{
    width: 100%;
    height: 16px;
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    text-align: center;
    margin-top: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* 分页 */
.page_box{
    width:100%;
    height:63px;
    margin-top:30px;
    margin-bottom:100px;
}





</style>

<template>
    <div class="main_r_info_box">
        <img class="main_r_info_box_xidada_img" src="../../assets/imgs/xidada3.png" alt="">
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'xidada',
    props:['currentId'],
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            articleList: [],//右侧展示文章列表数据
            pageNum: 1,//分页的三个值
            pageSize: 9,
            total: 0,
            curtitle: ''
        }
    },
    watch: {
        currentId (val) {
            this.rightInfoFn(val);
        }
    },
    mounted() {
        this.rightInfoFn(this.currentId);//默认展示CIKD大讲堂
    },
    methods: {
        //加载右侧文章列表数据
        rightInfoFn(index, curNum=1){
            let _this = this;
            let menuId = index;
            let pageNum = curNum;
            let pageSize = 9;
            api.getArticleList(menuId, pageNum, pageSize).then(res => {
                if (res.data) {
                    _this.articleList = res.data.list;
                    _this.pageNum = res.data.pageNum;
                    _this.pageSize =res.data.pageSize;
                    _this.total =res.data.total;
                    _this.$emit('titleInfo',res.data.categoryPosition);//将当前所属栏目传给父组件
                    _this.curtitle = res.data.title;
                }
            });
        },
        //分页操作函数1
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        //分页操作函数2
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.rightInfoFn(this.currentId, val);
        }
    }
}
</script>

<style scoped>

.main_r_info_box{
    width:100%;
    margin-top:38px;
}
.main_r_info_box_xidada_img{
    display: block;
    width: 100%;
    margin-bottom: 120px;
}





</style>

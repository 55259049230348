<template>
    <div class="main_r_info_box">
        <h3 class="main_r_info_box_title1">“中国将设立国际发展知识中心，同各国一道研究和交流适合各自国情的发展理论和发展实践。” </h3>
        <h3 class="main_r_info_box_title2"> ——国家主席习近平，2015年</h3>
        <div class="main_r_info_box_title3"><span>领导致辞</span></div>
        <p class="main_r_info_box_info">
            中国国际发展知识中心是2015年9月习近平主席在联合国发展峰会上宣布设立的，
            2017年3月正式组建。同年8月，国际知识中心正式启动运行，习近平主席和联合
            国秘书长古特雷斯分别专门致信祝贺成立。习近平主席在贺信中强调，希望国际知
            识中心为研究交流发展理念、促进国际发展合作、推动全球落实可持续发展议程作
            出积极贡献。古特雷斯秘书长在贺信中表示，相信国际知识中心与多个联合国机构
            的伙伴关系将促进知识分享，促进经验交流和发挥思想引领作用。
        </p>
        <p class="main_r_info_box_info">
            国际知识中心的宗旨，是借鉴中国发展经验，结合各自不同国情，同各国一道研究
            和交流发展理论和发展实践。中心将秉持“唯实求真、合作包容、交流互鉴”的价值
            理念，多方式、多途径推动发展领域的国际合作与交流，在交流互鉴中贡献中国发
            展的智慧和可持续发展的中国方案。
        </p>
        <p class="main_r_info_box_info">
            国际知识中心将牢记使命、不负重托，努力成为中国发展知识高素质研究者和有效
            交流者，成为全球发展知识网络的重要成员。“道阻且长，行则将至”。我相信，通
            过统筹协调国内外发展研究资源，国际知识中心一定能够夯实中国发展知识基础，
            为研究交流发展理念、促进国际发展合作、推动全球落实可持续发展议程，作出
            更加重要而独特的贡献。
        </p>
        <p class="main_r_info_box_info">
            谨向国内外所有关心和支持国际知识中心工作的机构和人士，表示衷心的感谢！ 
        </p>

    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'talking',
    props:['currentId'],
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            articleList: [],//右侧展示文章列表数据
            pageNum: 1,//分页的三个值
            pageSize: 9,
            total: 0,
            curtitle: ''
        }
    },
    watch: {
        currentId (val) {
            this.rightInfoFn(val);
        }
    },
    mounted() {
        this.rightInfoFn(this.currentId);//默认展示CIKD大讲堂
    },
    methods: {
        //加载右侧文章列表数据
        rightInfoFn(index, curNum=1){
            let _this = this;
            let menuId = index;
            let pageNum = curNum;
            let pageSize = 9;
            api.getArticleList(menuId, pageNum, pageSize).then(res => {
                if (res.data) {
                    _this.articleList = res.data.list;
                    _this.pageNum = res.data.pageNum;
                    _this.pageSize =res.data.pageSize;
                    _this.total =res.data.total;
                    _this.$emit('titleInfo',res.data.categoryPosition);//将当前所属栏目传给父组件
                    _this.curtitle = res.data.title;
                }
            });
        },
        //分页操作函数1
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        //分页操作函数2
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.rightInfoFn(this.currentId, val);
        }
    }
}
</script>

<style scoped>

.main_r_info_box{
    width:100%;
    margin-top:38px;
}
.main_r_info_box_xidada_img{
    display: block;
    width: 100%;
    margin-bottom: 120px;
}
.main_r_info_box_title1{
    width: 850px;
    font-size: 30px;
    font-family: 'SimSun';
    text-align: left;
    line-height: 2;
}
.main_r_info_box_title2{
    width: 850px;
    font-size: 30px;
    font-family: 'SimSun';
    text-align: right;
}
.main_r_info_box_title3{
    width: 100%;
    height: 37px;
    line-height: 37px;
    border-bottom: 1px solid #D1D1D1;
    margin-top:80px;
}
.main_r_info_box_title3 span{
    display: block;
    width: 84px;
    height: 100%;
    font-size: 18px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #1D8BC7;
    line-height: 37px;
    border-bottom:1px solid #1D8BC7;
    text-align: center;
}
.main_r_info_box_info{
    width: 876px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #333333;
    margin: 33px auto 0;
    text-align: left;
    line-height: 2;
    text-indent:2em
}





</style>

<template>
    <div class="main_r_info_box">
        <div class="main_r_info_box_ul" style="position:relative;">
            <div class="swiper-container gallery-thumbs">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,i) in titles" :key="i">{{item}}年</div>
                </div>
            </div>
            <div class="swiper">
                <div class="swiper-wrapper">
                    
                    <ul v-if="articleList.length > 0" v-for="(sitem,j) in articleList" :key="j" class="swiper-slide main_r_info_box_ul" >
                        <h4 class="team_title">{{titles[j]}}</h4>
                        <li v-if="sitem.docs.length > 0" v-for="(item,i) in sitem.docs" :key="i">
                            <a :title="item.contentTitle" :style="i%2 ? 'display:block;float:right' : 'float:left;'">
                                <div class="main_r_info_box_li_imgbox" :style="i%2 ? 'float:right' : 'float:left;'">
                                    <img class="main_img" :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                                </div>
                                <div class="main_r_info_box_li_infobox" :style="i%2 ? 'float:left' : 'float:right;'">
                                    <h4 class="main_r_info_box_li_h4">{{item.contentDatetime ? item.contentDatetime.substring(0,10) : ''}}</h4>
                                    <p class="main_r_info_box_li_p">{{item.contentTitle ? item.contentTitle : ''}}</p>
                                    <div class="box_dot" :style="i%2 ? 'left:-4px;' : 'right:-5px;'"></div>
                                </div>
                            </a>
                        </li>
                        <div v-if="sitem.docs.length > 0" class="box_middle_bg_border"></div>
                        <el-empty v-if="sitem.docs.length <= 0" description="暂无数据!"></el-empty>
                    </ul>
                </div>
                
                <!-- 如果需要导航按钮 -->
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
        </div>
        
        <!-- <div v-if="articleList.length > 0" class="page_box">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageNum"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div> -->
        <el-empty v-if="articleList.length <= 0" description="暂无数据!"></el-empty>
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";
import Swiper from 'swiper/dist/js/swiper.min.js';
import 'swiper/dist/css/swiper.min.css';

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'team2',
    props:['currentId'],
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            articleList: [],//右侧展示文章列表数据
            articleList2: [],//副主任列表
            titles: [],//所有标题集合
            pageNum: 1,//分页的三个值
            pageSize: 9,
            total: 0,
            curtitle: ''
        }
    },
    watch: {
        currentId (val) {
            this.rightInfoFn(val);
        }
    },
    mounted() {
        this.rightInfoFn(this.currentId);//默认展示CIKD大讲堂
    },
    methods: {
        //加载右侧文章列表数据
        rightInfoFn(index, curNum=1){
            let _this = this;

            let menuId = index;
            let pageNum = curNum;
            let pageSize = 100;
            let isKY = true;
            api.getArticleList(menuId, pageNum, pageSize, isKY).then(res => {
                if (res.data) {
                    _this.articleList = Object.values(res.data.map).reverse();
                    _this.titles = Object.keys(res.data.map).reverse();
                    _this.pageNum = res.data.pageNum;
                    _this.pageSize =res.data.pageSize;
                    _this.total =res.data.total;
                    console.log(Object.keys(res.data.map),'keys')
                    console.log(Object.values(res.data.map),'values')
                    console.log(res.data.map,'res.data.map');
                    _this.$emit('titleInfo',res.data.categoryPosition);//将当前所属栏目传给父组件
                    _this.curtitle = res.data.title;

                    setTimeout(()=>{
                        var galleryThumbs = new Swiper('.gallery-thumbs', {
                            spaceBetween: 0,
                            slidesPerView: 1,
                            loop: false,
                            simulateTouch: false,
                            freeMode: true,
                            loopedSlides: 5, //looped slides should be the same
                            watchSlidesVisibility: true,
                            watchSlidesProgress: true,
                        });

                        var mySwiper = new Swiper ('.swiper', {
                            spaceBetween: 0,
                            slidesPerView: 1,
                            autoHeight: true,
                            loop:false,
                            simulateTouch: false,
                            loopedSlides: 5, //looped slides should be the same
                            // 如果需要前进后退按钮
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                            thumbs: {
                                swiper: galleryThumbs,
                            },
                        });    
                    },400); 
                }
            });
        },
        //分页操作函数1
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        //分页操作函数2
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.rightInfoFn(this.currentId, val);
        }
    }
}
</script>

<style scoped>

.main_r_info_box{
    width:100%;
    margin-top:38px;
}
.main_r_info_box_ul{
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
    position: relative;
    margin-bottom: 40px;
}
.main_r_info_box_ul li{
    width: 100%;
    min-height:174px;
    overflow: hidden;
}
.main_r_info_box_ul li a{
    height:100%;
    width: 446px;
    display: block;
}

.main_r_info_box_li_imgbox{
    width: 200px;
    height: 135px;
    overflow: hidden;
    position: relative;
    border: 1px solid #ccc;
    float: left;
}
.main_r_info_box_li_imgbox .main_img{
    width:100%;
    display: block;
}
.main_r_info_box_li_infobox{
    float: right;
    width:242px;
    height: 100%;
    position: relative;
}
.main_r_info_box_li_p{
    width: 192px;
    /* height: 76px; */
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: left;
    margin: 9px auto 0;
    text-overflow: ellipsis;
    /* display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; */
    overflow: hidden;
}
.main_r_info_box_li_h4{
    width: 90%;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-bottom: 1px solid #D9D7D7;
    padding-left: 25px;
}
.team_title{
    width:100%;
    text-align: center;
    margin-bottom: 35px;
    height: 24px;
    font-size: 24px;
    font-weight: bold;
    color: #3488CF;
    line-height: 22px;
}


/* 分页 */
.page_box{
    width:100%;
    height:63px;
    margin-top:30px;
    margin-bottom:100px;
}
.swiper {
    width: 100%;
    /* height: 300px; */
}
.gallery-thumbs{
    width:278px;
    height: 10%;
    box-sizing: border-box;
    padding: 10px 0;
}
.gallery-thumbs .swiper-slide{
    height: 100%;
    color: #333333;
    margin-bottom: 41px;
}
.gallery-thumbs .swiper-slide-thumb-active {
    color: #3488CF;
}
.swiper-button-prev{
    width: 32px;
    height: 32px;
    background-image: url('../../assets/imgs/icon_l.png');
    background-size: cover;
    left: 273px;
    right: auto;
    top: 26px;
}
.swiper-button-prev:hover{
    background-image: url('../../assets/imgs/icon_l_hover.png');
}
.swiper-button-next{
    width: 32px;
    height: 32px;
    background-image: url('../../assets/imgs/icon_r.png');
    background-size: cover;
    right: 273px;
    left: auto;
    top: 26px;
}
.swiper-button-next:hover{
    background-image: url('../../assets/imgs/icon_r_hover.png');
}
.box_dot{
    width: 9px;
    height: 9px;
    background: #FFFFFF;
    border: 1px solid #D9D7D7;
    border-radius: 50%;
    position: absolute;
    top: 37px;
    right: -5px;
    background-color: #fff;
    z-index: 10;
}
.box_middle_bg_border{
    width:1px;
    height: 100%;
    background-color: #EFEEEE;
    position: absolute;
    top:40px;
    left:50%;
}





</style>

<template>
	<div class="main_r_info_box" style="margin-bottom: 30px; margin-top: 30px">
		<a class="top_btn1" href="/aboutus?menuId=1493483773392134145">
			历任领导
			<img src="../../assets/imgs/arrow_r.png" alt="" />
		</a>
		<h3 class="top_btn2">现任领导:</h3>
		<ul
			v-if="articleList.docs && articleList.docs.length > 0"
			class="main_r_info_box_ul"
			style="border-bottom: 1px solid #e3e3e3"
		>
			<li
				v-for="(item, i) in articleList.docs"
				:key="i"
				style="width: 240px"
			>
				<a
					:href="
						item.contentIsChain == '1'
							? item.contentChain
							: '/detail?docId=' + item.id
					"
					:title="item.contentTitle"
					target="_blank"
					style="display: block"
				>
					<div class="main_r_info_box_li_imgbox">
						<img
							class="main_img"
							:src="
								item.imgSrc && item.imgSrc.length > 0
									? imgBase + item.imgSrc[0]
									: defaultImg1
							"
							alt=""
						/>
					</div>
					<h4 class="main_r_info_box_li_h4">
						{{ item.contentTitle ? item.contentTitle : '' }}
					</h4>
					<p class="main_r_info_box_li_p">
						{{
							item.contentDescription
								? item.contentDescription
								: ''
						}}
					</p>
				</a>
			</li>
		</ul>
		<ul
			v-if="articleList3.docs && articleList3.docs.length > 0"
			class="main_r_info_box_ul"
			style="border-bottom: 1px solid #e3e3e3"
		>
			<li
				v-for="(item, i) in articleList3.docs"
				:key="i"
				style="width: 240px"
			>
				<a
					:href="
						item.contentIsChain == '1'
							? item.contentChain
							: '/detail?docId=' + item.id
					"
					:title="item.contentTitle"
					target="_blank"
					style="display: block"
				>
					<div class="main_r_info_box_li_imgbox">
						<img
							class="main_img"
							:src="
								item.imgSrc && item.imgSrc.length > 0
									? imgBase + item.imgSrc[0]
									: defaultImg1
							"
							alt=""
						/>
					</div>
					<h4 class="main_r_info_box_li_h4">
						{{ item.contentTitle ? item.contentTitle : '' }}
					</h4>
					<p class="main_r_info_box_li_p">
						{{
							item.contentDescription
								? item.contentDescription
								: ''
						}}
					</p>
				</a>
			</li>
		</ul>
		<ul
			v-if="articleList4.docs && articleList4.docs.length > 0"
			class="main_r_info_box_ul"
			style="border-bottom: 1px solid #e3e3e3"
		>
			<li
				v-for="(item, i) in articleList4.docs"
				:key="i"
				style="width: 240px"
			>
				<a
					:href="
						item.contentIsChain == '1'
							? item.contentChain
							: '/detail?docId=' + item.id
					"
					:title="item.contentTitle"
					target="_blank"
					style="display: block"
				>
					<div class="main_r_info_box_li_imgbox">
						<img
							class="main_img"
							:src="
								item.imgSrc && item.imgSrc.length > 0
									? imgBase + item.imgSrc[0]
									: defaultImg1
							"
							alt=""
						/>
					</div>
					<h4 class="main_r_info_box_li_h4">
						{{ item.contentTitle ? item.contentTitle : '' }}
					</h4>
					<p
						class="main_r_info_box_li_p"
						style="width: 99% !important"
					>
						{{
							item.contentDescription
								? item.contentDescription
								: ''
						}}
					</p>
				</a>
			</li>
		</ul>
		<ul
			v-if="articleList2.docs && articleList2.docs.length > 0"
			class="main_r_info_box_ul"
		>
			<li v-for="(item, i) in articleList2.docs" :key="i">
				<a
					:href="
						item.contentIsChain == '1'
							? item.contentChain
							: '/detail?docId=' + item.id
					"
					:title="item.contentTitle"
					target="_blank"
					style="display: block"
				>
					<div class="main_r_info_box_li_imgbox">
						<img
							class="main_img"
							:src="
								item.imgSrc && item.imgSrc.length > 0
									? imgBase + item.imgSrc[0]
									: defaultImg1
							"
							alt=""
						/>
					</div>
					<h4 class="main_r_info_box_li_h4">
						{{ item.contentTitle ? item.contentTitle : '' }}
					</h4>
					<p class="main_r_info_box_li_p">
						{{
							item.contentDescription
								? item.contentDescription
								: ''
						}}
					</p>
				</a>
			</li>
		</ul>
		<!-- <div v-if="articleList.length > 0" class="page_box">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageNum"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div> -->
		<el-empty
			v-if="articleList.length <= 0"
			description="暂无数据!"
		></el-empty>
	</div>
</template>

<script>
import $ from 'jquery';
import api from '@/../static/api/api.js';

import defaultImg1 from '@/assets/imgs/default.png';
import defaultImg2 from '@/assets/imgs/default2.png';
import defaultImg3 from '@/assets/imgs/default3.png';

export default {
	name: 'team2',
	props: ['currentId'],
	data() {
		return {
			imgBase: this.$store.state.imgBase,
			defaultImg1: defaultImg1, //轮播图默认图片
			defaultImg2: defaultImg2, //研究项目默认图
			defaultImg3: defaultImg3, //交流传播默认图
			articleList: [], //右侧展示文章列表数据
			articleList2: [], //副主任列表
			articleList3: [], //第一副主任
			articleList4: [], //常务副主任
			pageNum: 1, //分页的三个值
			pageSize: 9,
			total: 0,
			curtitle: '',
		};
	},
	watch: {
		currentId(val) {
			this.rightInfoFn(val);
		},
	},
	mounted() {
		this.rightInfoFn(this.currentId); //默认展示CIKD大讲堂
	},
	methods: {
		// 点击跳转到历任领导
		pagetobefpage() {
			window.location.href = '/aboutus?menuId=1493483773392134145';
		},
		//加载右侧文章列表数据
		rightInfoFn(index, curNum = 1) {
			let _this = this;
			let menuId = index;
			let pageNum = curNum;
			let pageSize = 9;
			let isKY = true;
			api.getArticleList(menuId, pageNum, pageSize, isKY).then((res) => {
				if (res.data) {
					_this.articleList = res.data.map['主任'];
					_this.articleList2 = res.data.map['副主任'];
					_this.articleList3 = res.data.map['第一副主任'];
					_this.articleList4 = res.data.map['常务副主任'];
					_this.pageNum = res.data.pageNum;
					_this.pageSize = res.data.pageSize;
					_this.total = res.data.total;
					console.log(res.data.map, 'res.data.map');
					console.log(res.data, 'res.data');
					_this.$emit('titleInfo', res.data.categoryPosition); //将当前所属栏目传给父组件
					_this.curtitle = res.data.title;
				}
			});
		},
		//分页操作函数1
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
		},
		//分页操作函数2
		handleCurrentChange(val) {
			// console.log(`当前页: ${val}`);
			this.rightInfoFn(this.currentId, val);
		},
	},
};
</script>

<style scoped>
.main_r_info_box {
	width: 100%;
	margin-top: 38px;
}
.main_r_info_box_ul {
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin-top: 30px;
}
.main_r_info_box_ul li {
	width: 240px;
	/* height:228px; */
	overflow: hidden;
	float: left;
	margin-right: 16px;
	margin-bottom: 44px;
}
.main_r_info_box_ul li:nth-child(4n) {
	margin-right: 0;
}
/* .main_r_info_box_ul li:nth-child(6){
    margin-right: 0;
}
.main_r_info_box_ul li:nth-child(9){
    margin-right: 0;
} */
.main_r_info_box_li_imgbox {
	width: 140px;
	height: 168px;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}
.main_r_info_box_li_imgbox .main_img {
	width: 100%;
	display: block;
}
.main_r_info_box_li_p {
	width: 100%;
	/* height: 16px; */
	font-size: 14px;
	/* font-family: STXihei; */
	font-weight: 400;
	color: #333333;
	/* line-height: 16px; */
	text-align: center;
	margin-top: 9px;
	/* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
}
.main_r_info_box_li_h4 {
	width: 100%;
	height: 16px;
	font-size: 16px;
	/* font-family: STXihei; */
	font-weight: 400;
	color: #333333;
	line-height: 16px;
	text-align: center;
	margin-top: 18px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/* 分页 */
.page_box {
	width: 100%;
	height: 63px;
	margin-top: 30px;
	margin-bottom: 100px;
}

.top_btn1 {
	display: block;
	color: rgb(24, 119, 201);
	overflow: hidden;
	text-align: left;
	margin-left: 14px;
}
.top_btn1 img {
	display: inline-block;
	margin-left: 4px;
}

.top_btn2 {
	display: block;
	color: rgb(24, 119, 201);
	overflow: hidden;
	text-align: left;
	margin-left: 14px;
	margin-top: 30px;
	border-bottom: 1px solid rgb(227, 227, 227);
	line-height: 34px;
	/* margin-bottom: 46px; */
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main_r_info_box",staticStyle:{"margin-bottom":"30px","margin-top":"30px"}},[_vm._m(0),_c('h3',{staticClass:"top_btn2"},[_vm._v("现任领导:")]),(_vm.articleList.docs && _vm.articleList.docs.length > 0)?_c('ul',{staticClass:"main_r_info_box_ul",staticStyle:{"border-bottom":"1px solid #e3e3e3"}},_vm._l((_vm.articleList.docs),function(item,i){return _c('li',{key:i,staticStyle:{"width":"240px"}},[_c('a',{staticStyle:{"display":"block"},attrs:{"href":item.contentIsChain == '1'
							? item.contentChain
							: '/detail?docId=' + item.id,"title":item.contentTitle,"target":"_blank"}},[_c('div',{staticClass:"main_r_info_box_li_imgbox"},[_c('img',{staticClass:"main_img",attrs:{"src":item.imgSrc && item.imgSrc.length > 0
									? _vm.imgBase + item.imgSrc[0]
									: _vm.defaultImg1,"alt":""}})]),_c('h4',{staticClass:"main_r_info_box_li_h4"},[_vm._v(" "+_vm._s(item.contentTitle ? item.contentTitle : '')+" ")]),_c('p',{staticClass:"main_r_info_box_li_p"},[_vm._v(" "+_vm._s(item.contentDescription ? item.contentDescription : '')+" ")])])])}),0):_vm._e(),(_vm.articleList3.docs && _vm.articleList3.docs.length > 0)?_c('ul',{staticClass:"main_r_info_box_ul",staticStyle:{"border-bottom":"1px solid #e3e3e3"}},_vm._l((_vm.articleList3.docs),function(item,i){return _c('li',{key:i,staticStyle:{"width":"240px"}},[_c('a',{staticStyle:{"display":"block"},attrs:{"href":item.contentIsChain == '1'
							? item.contentChain
							: '/detail?docId=' + item.id,"title":item.contentTitle,"target":"_blank"}},[_c('div',{staticClass:"main_r_info_box_li_imgbox"},[_c('img',{staticClass:"main_img",attrs:{"src":item.imgSrc && item.imgSrc.length > 0
									? _vm.imgBase + item.imgSrc[0]
									: _vm.defaultImg1,"alt":""}})]),_c('h4',{staticClass:"main_r_info_box_li_h4"},[_vm._v(" "+_vm._s(item.contentTitle ? item.contentTitle : '')+" ")]),_c('p',{staticClass:"main_r_info_box_li_p"},[_vm._v(" "+_vm._s(item.contentDescription ? item.contentDescription : '')+" ")])])])}),0):_vm._e(),(_vm.articleList4.docs && _vm.articleList4.docs.length > 0)?_c('ul',{staticClass:"main_r_info_box_ul",staticStyle:{"border-bottom":"1px solid #e3e3e3"}},_vm._l((_vm.articleList4.docs),function(item,i){return _c('li',{key:i,staticStyle:{"width":"240px"}},[_c('a',{staticStyle:{"display":"block"},attrs:{"href":item.contentIsChain == '1'
							? item.contentChain
							: '/detail?docId=' + item.id,"title":item.contentTitle,"target":"_blank"}},[_c('div',{staticClass:"main_r_info_box_li_imgbox"},[_c('img',{staticClass:"main_img",attrs:{"src":item.imgSrc && item.imgSrc.length > 0
									? _vm.imgBase + item.imgSrc[0]
									: _vm.defaultImg1,"alt":""}})]),_c('h4',{staticClass:"main_r_info_box_li_h4"},[_vm._v(" "+_vm._s(item.contentTitle ? item.contentTitle : '')+" ")]),_c('p',{staticClass:"main_r_info_box_li_p",staticStyle:{"width":"99% !important"}},[_vm._v(" "+_vm._s(item.contentDescription ? item.contentDescription : '')+" ")])])])}),0):_vm._e(),(_vm.articleList2.docs && _vm.articleList2.docs.length > 0)?_c('ul',{staticClass:"main_r_info_box_ul"},_vm._l((_vm.articleList2.docs),function(item,i){return _c('li',{key:i},[_c('a',{staticStyle:{"display":"block"},attrs:{"href":item.contentIsChain == '1'
							? item.contentChain
							: '/detail?docId=' + item.id,"title":item.contentTitle,"target":"_blank"}},[_c('div',{staticClass:"main_r_info_box_li_imgbox"},[_c('img',{staticClass:"main_img",attrs:{"src":item.imgSrc && item.imgSrc.length > 0
									? _vm.imgBase + item.imgSrc[0]
									: _vm.defaultImg1,"alt":""}})]),_c('h4',{staticClass:"main_r_info_box_li_h4"},[_vm._v(" "+_vm._s(item.contentTitle ? item.contentTitle : '')+" ")]),_c('p',{staticClass:"main_r_info_box_li_p"},[_vm._v(" "+_vm._s(item.contentDescription ? item.contentDescription : '')+" ")])])])}),0):_vm._e(),(_vm.articleList.length <= 0)?_c('el-empty',{attrs:{"description":"暂无数据!"}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"top_btn1",attrs:{"href":"/aboutus?menuId=1493483773392134145"}},[_vm._v(" 历任领导 "),_c('img',{attrs:{"src":require("../../assets/imgs/arrow_r.png"),"alt":""}})])}]

export { render, staticRenderFns }